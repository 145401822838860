import { spreadSearchQuery } from "helper/utility-helper";
import axios from "../../axios-api";

const BASE_REPORT_API_URL = `${process.env.REACT_APP_DM_ENDPOINT}/reports`;

export const documentService = {
  uploadFile,
  getResourceByBase64,
  getResourceItemsId,
  downloadItem,
  downloadWithoutBlobItem,
  deleteResourceById,
  deleteItemByBase64AndIdAndItemId,
  updateFile,
  uploadFileDocument,
  getReportByCaseId,
  getReport5D,
  // updateContact,
  // deleteContact,
  uploadImage,

  // Common
  getReportByPath,
};

let BASE_API_URL = `${process.env.REACT_APP_DM_ENDPOINT}/drives`;

if (process.env.REACT_APP_DM_SERVICE === "DOCUMENT") {
  BASE_API_URL = `${process.env.REACT_APP_DM_ENDPOINT}/documents`;
} else {
  BASE_API_URL = `${process.env.REACT_APP_DM_ENDPOINT}/drives`;
}

function uploadFile(data) {
  const req = {
    ...data,
  };

  const reqData = JSON.stringify(req);

  return axios.post(
    `${BASE_API_URL}`,
    reqData,
    {
      onUploadProgress: (progressEvent) => {
        // console.log(
        //   "Upload progress: " +
        //     (progressEvent.loaded / progressEvent.total) * 100
        // );
      },
    }
    // {
    // headers: {
    //   "Content-Type": undefined,
    //   'Accept':'application/json'
    // },
    // }
  );
}

function uploadFileDocument(fileData) {
  var formData = new FormData();
  if (Array.isArray(fileData?.multipartFile)) {
    fileData.multipartFile.forEach((item) => {
      formData.append("multipartFile", item);
    });
  }
  formData.append("group", fileData.group);
  formData.append("itemTag", fileData.itemTag);
  formData.append("resourceTag", fileData.resourceTag);

  // formData.append("type", fileData.type);
  formData.append("remark", fileData.remark);
  formData.append("category", fileData.category);
  return axios.post(`${BASE_API_URL}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function updateFile(id, data) {
  const reqData = JSON.stringify(data);

  return axios.put(
    `${BASE_API_URL}/${id}`,
    reqData,
    {
      onUploadProgress: (progressEvent) => {
        console.log(
          "Upload progress: " +
            (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    }
    // {
    // headers: {
    //   "Content-Type": undefined,
    //   'Accept':'application/json'
    // },
    // }
  );
}

function getResourceByBase64(search) {
  let q = spreadSearchQuery(search);
  return axios.get(`${BASE_API_URL}?q${q}`);
}

function getResourceItemsId(driveId) {
  return axios.get(`${BASE_API_URL}/${driveId}/driveItems`);
}

function downloadItem(driveId, driveItemId) {
  return axios.get(
    `${BASE_API_URL}/${driveId}/driveItems/${driveItemId}/downloads`,
    {
      responseType: "blob",
    }
  );
}

// drives
// function downloadWithoutBlobItem(driveId) {
//   return axios.get(
//     `${BASE_API_URL}/${driveId}/downloads`
//   );
// }

// document
// function downloadWithoutBlobItem(driveId, driveItemId) {
//   return axios.get(
//     `${BASE_API_URL}/${driveId}/driveItems/${driveItemId}/downloads`
//   );
// }

function downloadWithoutBlobItem(driveId, driveItemId) {
  if (process.env.REACT_APP_DM_SERVICE === "DOCUMENT") {
    return axios.get(
      `${BASE_API_URL}/${driveId}/driveItems/${driveItemId}/downloads`
    );
  } else {
    return axios.get(`${BASE_API_URL}/${driveId}/downloads`);
  }
}

function deleteResourceById(driveId) {
  return axios.delete(`${BASE_API_URL}/${driveId}`);
}

function deleteItemByBase64AndIdAndItemId(base64, driveId, itemId) {
  return axios.delete(`${BASE_API_URL}/${base64}/${driveId}/items/${itemId}`);
}
// function deleteContact(pId, contactId) {
//   return axios.delete(`${BASE_API_URL}/${pId}/contacts/${contactId}`);
// }

function getReportByCaseId(data, pathReport) {
  let q = spreadSearchQuery(data);
  return axios.get(`${BASE_REPORT_API_URL}${pathReport}?q${q}`, {
    responseType: "blob",
  });
}

function getReport5D(data, pathReport) {
  let q = spreadSearchQuery(data);
  return axios.get(`${BASE_REPORT_API_URL}${pathReport}?q${q}`, {
    responseType: "blob",
  });
}

function uploadImage(fileData, fileName) {
  var formData = new FormData();
  if (Array.isArray(fileData?.multipartFile)) {
    fileData.multipartFile.forEach((item) => {
      formData.append("data", item);
    });
  }
  formData.append("type", "i");
  formData.append("fileName", fileName);
  formData.append("description", "desc");
  return axios.post(`${BASE_REPORT_API_URL}/upload-images`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function getReportByPath(query, pathReport) {
  let q = spreadSearchQuery(query);
  return axios.get(`${BASE_REPORT_API_URL}/${pathReport}?q${q}`, {
    responseType: "blob",
  });
}
